/* eslint-disable import/order */
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";
import mask from "@alpinejs/mask";
import * as Sentry from "@sentry/browser";
import Alpine from "alpinejs";
import Flipping from "flipping/dist/flipping.web";
import Sortable from "sortablejs";
import { RadioGroup, Tooltip } from "./components";
import { PushNotification } from "./push";

window.htmx = require("htmx.org");
window.Sortable = Sortable;

require("htmx.org/dist/ext/sse");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9284254105354939bf2bc79f434ab019@o1428391.ingest.sentry.io/4504338651873280",
  });
}

Alpine.plugin(collapse);
Alpine.plugin(mask);
Alpine.plugin(intersect);

document.addEventListener("alpine:init", () => {
  Alpine.directive(
    "destroy",
    (el, { expression }, { evaluateLater, cleanup }) => {
      const clean = evaluateLater(expression);
      cleanup(() => {
        clean();
      });
    }
  );

  /**
   * Simple countdown timer
   */
  Alpine.data("Countdown", (target, estimate) => ({
    target: null,
    estimate: null,
    timer: null,
    time: null,

    start() {
      this.target = new Date(target);
      this.estimate = estimate;
      const timer = () => {
        this.time = this.target - Date.now();
        this.timer = setTimeout(timer, 1000);
      };
      timer();
    },
    get minutes() {
      return Math.ceil(this.time / 1000 / 60);
    },
    get percentage() {
      return (this.minutes / this.estimate) * 100;
    },
  }));

  /**
   * Order actions
   */
  Alpine.data("OrderActions", (status) => ({
    status: null,
    minutes: null,
    // Minimum time left to show 'preparation' button
    prepLimit: 0,
    // Minimum time left to show 'delivery' button
    doneLimit: -15,

    init() {
      this.status = status;
      if (this.countdown) {
        this.minutes = this.countdown.minutes;
        const updateMinutes = () => {
          this.minutes = this.countdown.minutes;
          setTimeout(updateMinutes, 1000 * 10);
        };
        updateMinutes();
      }
    },
    showPreparation() {
      return this.minutes >= this.prepLimit && this.status === "CONFIRMED";
    },
    showReady() {
      // delivery on the way or pickup ready button
      return (
        (this.minutes >= this.doneLimit && this.status === "PREPARATION") ||
        (!this.showPreparation() && !this.showDone())
      );
    },
    showDone() {
      return (
        this.minutes < this.doneLimit ||
        this.status === "DELIVERY" ||
        this.status === "PICKUP_READY"
      );
    },
  }));

  Alpine.store("ping", {
    lastPing: null,
    isOnline: undefined,
    get pingTime() {
      if (this.lastPing) {
        return new Intl.DateTimeFormat(document.documentElement.lang, {
          weekday: "short",
          day: "numeric",
          month: "short",
          hour: "numeric",
          minute: "numeric",
        }).format(this.lastPing);
      }
      return "";
    },
  });

  Alpine.data("ActiveOrders", (url, sound) => ({
    evtsource: null,
    pingTimer: null,
    lastCheck: null,
    audio: null,

    init() {
      this.createSource();
      this.initAudio();
      this.initOrderElements();
      this.acquireWakeLock();
      this.checkNotificationPermission();
    },
    get incomingEl() {
      return document.getElementById("incoming-orders");
    },
    get outgoingEl() {
      return document.getElementById("outgoing-orders");
    },
    initOrderElements() {
      if (!this.incomingEl || !this.outgoingEl) {
        console.warn("Incoming or Outgoing order list element not present.");
        return;
      }
      this.sortableIncoming = Sortable.create(this.incomingEl, {
        //group: { name: "orders", pull: true, put: false },
        animation: 150,
        delay: 100,
        delayOnTouchOnly: true,
        filter: "[data-sortable-ignore]",
      });
      this.sortableOutgoing = Sortable.create(this.outgoingEl, {
        // group: { name: "orders", pull: false, put: true },
        animation: 150,
        delay: 100,
        delayOnTouchOnly: true,
        filter: "[data-sortable-ignore]",
      });
    },
    initAudio() {
      setTimeout(async () => {
        this.audio = new Audio(sound);
        this.audio.volume = 0.1;
        try {
          await this.audio.play();
          this.audio.pause();
          this.audio.volume = 1.0;
          console.info("audio playback allowed");
        } catch (e) {
          this.audio.volume = 1.0;
          console.warn("audio playback prevented");
          htmx.ajax("GET", "/org/orders/activate/", {
            target: null,
            swap: "none",
            indicator: null,
          });
        }
      }, 500);
    },
    playSound(count = 1) {
      if (!this.audio) {
        setTimeout(() => {
          this.playSound(count);
        }, 1000);
        return;
      }
      this.audio.currentTime = 0;
      const play = () => {
        console.log(`Playing sound count ${count}`);
        try {
          this.audio.play();
        } catch (e) {
          console.error(e);
          this.notify(e.toString());
        }
        count -= 1;
        if (count <= 0) {
          this.audio.removeEventListener("ended", play);
        }
      };
      play();
      this.audio.addEventListener("ended", play);
    },
    createSource() {
      this.evtsource = new EventSource(url, { withCredentials: true });

      this.lastCheck = new Date();
      this.pingTimer = setTimeout(this.reconnect.bind(this), 15000);
      this.evtsource.addEventListener("ping", (e) => {
        this.$store.ping.lastPing = new Date(e.data);
        clearTimeout(this.pingTimer);
        this.lastCheck = new Date();
        this.pingTimer = setTimeout(this.reconnect.bind(this), 15000);
        this.checkOnline();
      });

      this.evtsource.addEventListener("play_sound", () => {
        this.playSound(2);
      });

      // Potentially flip the card from left to right
      const maybeFlipCard = (target, status) => {
        let targetContainer = this.outgoingEl;
        if (["received", "confirmed"].includes(status)) {
          targetContainer = this.incomingEl;
        }

        if (!targetContainer || targetContainer.contains(target)) {
          return;
        }

        const flipping = new Flipping();
        flipping.read();

        if (targetContainer.childElementCount === 0) {
          targetContainer.appendChild(target);
        } else {
          targetContainer.insertBefore(target, targetContainer.children[0]);
        }
        flipping.flip();
      };

      const handleCardUpdate = (event) => {
        console.info("handleCardUpdate", event);
        const payload = JSON.parse(event.data);
        let target = document.getElementById(`order-${payload.id}`);
        let swapTarget = "outerHTML";
        if (!target) {
          target = this.incomingEl;
          swapTarget = "afterbegin";
        } else {
          maybeFlipCard(target, payload.status);
        }
        if (target) {
          htmx.ajax("GET", payload.url, {
            target: target,
            swap: swapTarget,
            indicator: null,
          });
        }
        if (payload.modal_url) {
          htmx.ajax("GET", payload.modal_url, { swap: "none" });
          this.playSound(2);
        }
      };

      // These statuses only update the displayed information
      this.evtsource.addEventListener("order_received", handleCardUpdate);
      this.evtsource.addEventListener("order_confirmed", handleCardUpdate);
      this.evtsource.addEventListener("order_overdue", handleCardUpdate);
      this.evtsource.addEventListener("order_preparation", handleCardUpdate);
      this.evtsource.addEventListener("order_delivery", handleCardUpdate);
      this.evtsource.addEventListener("order_pickup_ready", handleCardUpdate);

      const handleCardRemove = (event) => {
        console.info("handleCardRemove", event);
        const payload = JSON.parse(event.data);
        const el = document.getElementById(`order-${payload.id}`);
        if (el) {
          el.dispatchEvent(new CustomEvent("remove"));
        }
      };

      // Actions that remove the card
      this.evtsource.addEventListener("hide_order", handleCardRemove);
      this.evtsource.addEventListener("order_rejected", handleCardRemove);
      this.evtsource.addEventListener("order_delivered", handleCardRemove);
      this.evtsource.addEventListener("order_pickedup", handleCardRemove);
    },
    reconnect() {
      console.warn("EventSource ping timeout. Reconnecting.");
      clearTimeout(this.pingTimer);
      this.checkOnline();
      this.evtsource.close();
      this.evtsource = null;
      this.createSource();
    },
    checkOnline() {
      if (!this.lastCheck || !this.$store.ping.lastPing) {
        return undefined;
      }
      const delta = this.lastCheck - this.$store.ping.lastPing;
      if (delta > 120000) {
        console.warn("Offline longer than 2 minutes", delta);
        this.showOfflineModal();
      }
      isOnline = delta < 10000;
      console.info(`isOnline? ${isOnline}`);
      this.$store.ping.isOnline = isOnline;
      return isOnline;
    },
    showOfflineModal() {
      if (document.visibilityState !== "visible") {
        return;
      }
      this.playSound(5);
      const modal = `
          <div id="modal-content" x-init="modalOpen = true">
            <h1 class="text-lg leading-6 font-medium text-red-600 pb-4 border-b">
              No connection!
            </h1>
            <p class="mt-4 text-base text-gray-500">
              Please check internet connectivity and reload the app.
            </p>
          </div>
        `;
      document.getElementById("modal-content").outerHTML = modal;
    },
    async acquireWakeLock() {
      if (!("wakeLock" in navigator)) {
        console.warn("Browser does not support wakelock!");
        return;
      }
      try {
        let wakeLock = await navigator.wakeLock.request("screen");
        document.addEventListener("visibilitychange", async () => {
          if (wakeLock !== null && document.visibilityState === "visible") {
            wakeLock = await navigator.wakeLock.request("screen");
          }
        });
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    },
    checkNotificationPermission() {
      setTimeout(async () => {
        if (Notification.permission !== "granted") {
          await Notification.requestPermission();
        }
        if (Notification.permission === "granted") {
          console.info("Notification permission granted.");
        }
      }, 600);
    },
    async notify(text) {
      if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        const notification = new Notification(text);
        // …
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        const permission = await Notification.requestPermission();
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification(text);
          // …
        }
      }
    },
    destroy() {
      if (this.evtsource) {
        clearTimeout(this.pingTimer);
        this.evtsource.close();
        this.evtsource = null;
      }
    },
  }));

  Alpine.data("CountNodes", (target) => ({
    count: 0,
    doCount() {
      this.count = target.childElementCount;
    },
    init() {
      this.doCount();
      const observer = new MutationObserver(() => this.doCount());
      observer.observe(target, {
        childList: true,
      });
    },
  }));

  Alpine.data("RadioGroup", RadioGroup);
  Alpine.data("Tooltip", Tooltip);
  Alpine.data("PushNotification", PushNotification);
});

Alpine.start();
