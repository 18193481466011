export const PushNotification = function ({ group, vapid, saveURL } = {}) {
  let registration;

  function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  return {
    initialized: false,
    isSubscribed: false,

    async init() {
      if (!("serviceWorker" in navigator)) {
        return console.error("ServiceWorker is not supported.");
      }
      registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        return console.error("ServiceWorker not yet installed.");
      }
      if (!registration.showNotification) {
        return console.error("Notifications are not supported.");
      }
      if (Notification.permission === "denied") {
        return console.error("Notifications are blocked.");
      }
      if (!("PushManager" in window)) {
        return console.error("PushManager is unavailable.");
      }
      console.log("PushNotification ready");

      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        await this.postSubscription("subscribe", subscription);
      }
      this.initialized = true;
    },

    async postSubscription(action, subscription) {
      const browser = navigator.userAgent
          .match(/(firefox|msie|chrome|safari|trident)/gi)[0]
          .toLowerCase(),
        userAgent = navigator.userAgent,
        data = {
          status_type: action,
          subscription: subscription.toJSON(),
          browser: browser,
          user_agent: userAgent,
          group: group,
        };

      const response = await fetch(saveURL, {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
        credentials: "include",
      });

      if (response.status === 201) {
        console.info("Successfully subscribed!");
        this.isSubscribed = true;
      } else if (response.status === 202) {
        try {
          await subscription.unsubscribe();
          console.info("Unsubscribed from push notifications.");
          this.isSubscribed = false;
        } catch (e) {
          console.error("Error unsubscribing from notifications.", e);
        }
      }
    },

    toggle() {
      if (this.isSubscribed) {
        this.unsubscribe();
      } else {
        this.subscribe();
      }
    },

    async subscribe() {
      const existingSubscription =
        await registration.pushManager.getSubscription();
      if (existingSubscription) {
        return existingSubscription;
      }
      const options = {
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(vapid),
      };
      const subscription = await registration.pushManager.subscribe(options);
      this.postSubscription("subscribe", subscription);
    },
    async unsubscribe() {
      const subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        return;
      }
      this.postSubscription("unsubscribe", subscription);
    },
  };
};
